body {
  background-color: #fff;
  color: #000;
  font-family: Arial, sans-serif;
}
.wrapper {
  max-width: 1000px;
}
a {
  text-decoration: none;
}
h4 {
  font-weight: bold;
}
.font-size-18px {
  font-size: 18px;
}
input[type="radio"] {
  cursor: pointer;
}
.gray {
  color: #868e9d;
}
.dark-gray {
  color: #444444;
}
.red {
  color: red;
}
.customTextbox {
  border-color: #33c6b4;
  border-radius: 0;
}
.custom-button {
  font-size: 18px;
  background-color: #33c6b4;
  color: #fff;
  border: 1px solid #f06e9c;
  padding-left: 15px !important;
  padding-right: 15px !important;
  padding-top: 5px;
  padding-bottom: 5px;
}
.custom-button:disabled {
  background-color: darkgray;
  color: #fff;
  border: 1px solid #f06e9c;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.pdf-icon {
  margin-top: -3px;
  width: 50px;
  height: 40px;
}
.pdf-button {
  display: inline-block;
  max-width: 300px;
  background-color: #e7702f;
  font-size: 16px;
}